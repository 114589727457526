<!-- Facturas de la remesa seleccionada -->

<template>
  <div class="cobros_pagos_liquidarFras" v-if="$store.state.datos_iniciales"> 
    <v-dialog v-model="dialog" content-class="modal" persistent> 
      <template v-slot:activator="{}">
        <v-btn 
          v-on:click="auxRecord.length? clickLiquidar() : errorRecords()"
          v-bind="$cfg.btn.busca_with_text"
          :disabled="!auxRecord.length"
          title="Liquidar facturas seleccionadas">
            LIQUIDAR
        </v-btn>       
      </template>

      <div class="centrado">
        <v-sheet :elevation="4">        
          <!-- Cabecera -->
          <div class="cabecera">
            <base_Header
              v-if="Entorno.header.header"       
              :Entorno="Entorno.header"
              @onEvent="dialog=false">
            </base_Header>                  
          </div>

          <!-- Contenido -->
          <div class="contenedor columna" style="width:750px"> 
            <div>
              <v-btn
                v-bind="$cfg.btra_cfg.standard"
                @click="liquidar()">
                <v-icon dark>{{ "mdi-check" }}</v-icon>
              </v-btn>  
              <v-btn class="ml-1"
                v-bind="$cfg.btra_cfg.standard"
                @click="dialog=false">
                  <v-icon dark>{{ "mdi-close" }}</v-icon>
              </v-btn>              
            </div>                       
            <vllabel
              style="padding-top:10px"
              label="Cuenta"            
              :txt="accionRow.cta">
            </vllabel>

            <div class="conflex">
              <vllabel
                style="width:50%"
                label="Email"            
                :txt="accionRow.email">
              </vllabel>

              <vllabel
                style="width:50%"
                label="Banco"            
                :txt="accionRow.ctaban">
              </vllabel>
            </div>
            <hr class="my-2">
            <div class="conflex">
              <!-- <vlinput   
                style="width:50%"           
                :label="schema.ctrls.fh.label"
                :txt="schema.ctrls.fh.value"
                type="date">
              </vlinput> -->
              <compfecha  
                style="width:50%"
                :schema="schema.ctrls.fh"
                :edicion="true">
              </compfecha>  

              <vllabel
                style="width:20%"
                label="Fras"            
                :txt="auxRecord.length">
              </vllabel>

              <div style="width:30%">
                <vllabel            
                  v-if="auxRecord.length>1"                  
                  label="A Pagar"            
                  :txt="total|num(2)">
                </vllabel>

                <uiText 
                  v-else
                  @Enter="liquidar()"
                  class="green lighten-5"
                  v-model="schema.ctrls.tot.value"
                  label="Importe a PAGAR"
                  format="money">                  
                </uiText>
                
                <!-- <v-text-field v-else reverse                                        
                    class="green lighten-5 ml-1 mr-1"
                    v-bind="$input"
                    v-model="total"
                    label="Importe a PAGAR">
                </v-text-field>                           -->
              </div>
              
            </div>            

            <div class="conflex">
              <v-select                 
                v-bind="$select"
                style="width:68%;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;"
                v-model="schema.ctrls.banco.value"
                :label="schema.ctrls.banco.label"
                :items="$store.state.datos_iniciales.pago_bancos"
                item-value="d"
                item-text="n">
              </v-select>
              
              <v-text-field  reverse
                  class="pink lighten-5 ml-1 mr-1"
                  v-bind="$input"
                  v-model="schema.ctrls.gas.value"
                  label="Gastos">
              </v-text-field>
            </div>

            <div class="conflex">
              <v-select  
                v-bind="$select"
                style="width:70%;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;"
                v-model="schema.ctrls.fpago.value"
                :label="schema.ctrls.fpago.label"
                :items="$store.state.datos_iniciales.pago_fpago"
                @change="calcula_gastos"
                item-value="d"
                item-text="n">
              </v-select>
               <vllabel                
                style="width:30%"
                label="a Banco"            
                :txt="banco|num(2)">
              </vllabel>
            </div>

            <v-textarea
              v-bind="$textarea"
              v-model="schema.ctrls.obs.value"
              :label="schema.ctrls.obs.label"
              rows="2"
              no-resize>
            </v-textarea>

            <div class="conflex">            
              <vlinput
                style="width:50%"
                :label="schema.ctrls.orden.label"            
                :txt="schema.ctrls.orden.value">
              </vlinput>            
              <vlinput
                style="width:50%"
                :label="schema.ctrls.asi.label"            
                :txt="schema.ctrls.asi.value">
              </vlinput>
              <!-- <small><span>(En blanco genera nuevo asiento)</span></small> -->
            </div>
          
          
          </div>
        </v-sheet> 
      </div>
    </v-dialog>
  </div>
</template>



<script>

  import { mixinCtrls } from "@/mixins/mixinCtrls.js";
  import plugs from "@/common/general_plugs";
  const base_Header = () => plugs.groute("base_Header.vue", "base");
  const compfecha = () => plugs.groute("compfecha.vue", "comp");
      
  export default {
    mixins: [mixinCtrls],
    components: { base_Header, compfecha },
    props: {
      padre: { type:String, default: '' }, 
      accionRow: { type:[Array, Object], default:null },
      auxRecord: { type:[Array, Object], default:null },
      tipo: { type:Number, default: 0 },
      total: { type:[String, Number], default: '' },
      componenteTipo: { type:String, default:'M' },
      Entorno: { type:Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.M)); }}
    },

    data() {
      return {
        schema: {
          ctrls: {
            fh: { f:'fh', label:'Fecha', value:"", 
              comp: {
                type:'fecha',
                format:'fh'
              }
            },
            
            tot: { f:'tot', label:'Importe a Pagar', value:"0"},
            gas: { f:'gas', label:'Gastos', value:"0"},
            banco: { f:'banco', label:'Banco', value:"1"},
            fpago: { f:'fpago', label:'Forma de Pago', value:"0"},
            obs: { f:'obs', label:'Observaciones', value:""},
            orden: { f:'orden', label:'Orden Pago', value:""},
            asi: { f:'asi', label:'Asiento', value:""}            
          }
        },
        api:'',
        stName:'stFcobros_pagos_liquidarFras',
        headers: {},

        dialog:false,
        itemsBanco: [],
        itemsFpago:[]
      };
    },

    mounted() {
      console.log(this.stName);
           
    },

    computed: {
      banco() {
        let tot=Number(this.schema.ctrls.tot.value); 
        let gas=Number(this.schema.ctrls.gas.value); 
        if (this.tipo=='1') gas=-gas;
        return tot-gas;
      },

    },


    methods: {
      ini_data() {
        console.log("DEV " + this.api + " ********************** (ini_data)");

        // configuro Entorno 
        this.Entorno.header.titulo = ""; 

        //
        this.schema.ctrls.tot.value=this.total;
        this.schema.ctrls.gas.value=0;

      },


      // muestro componente. Si es tip 1 devuelvo resultado confirm, si no, muestro ventana
      clickLiquidar() {
        console.log('clickLiquidar: ', this.tipo);
        if (this.tipo==1) {
          this.$root.$alert.open('Añadimos CUENTA/FACTURAS a REMESA?<br>'
                                + this.accionRow.cta + '<br>Fras: ' + this.auxRecord.length
                                + '<br>Importe: ' + this.total, 'confirm', null, ['SI', 'NO', 'LIQUIDAR'])
          .then(r=> {             
            switch (r) {
            case 0:
              this.add2Remesa();
              break;

            case 1:
              this.dialog= false;
              break;
              
            case 2:
              this.ini_component();
              break;
            }
          });
        
        }else {          
          this.ini_component();
        }        
      },

      //
      ini_component() { 
        this.dialog= true;      
        this.schema.ctrls.fh.value= this.actualDate();
        this.$set(this.Entorno.header, 'titulo', (this.tipo==1? 'PAGO' : 'COBRO') + ' FACTURAS');

        this.total=Math.round((this.total*100),2)/100; //redondeamos
        this.schema.ctrls.tot.value=this.total;

        this.schema.ctrls.fpago.value='1';
        this.calcula_gastos(); 
      },


      // añade la cuenta/facturas a una Remesa
      async add2Remesa() {
        if (!this.validar(1)) return; //validamos cuenta cif y banco 
       
        let args = { tipo:'fnc', accion: 'facturas', fn_args: { 
          accion:'pagos',
          fnt:'set',
          tipo: this.tipo,
          ban:0,
          imp:this.total,        
          tipo_cta:this.accionRow.tipo_cta,
          cta_id:this.accionRow.cta_id,
          fras:this.auxRecord
        }};
      
        let apiResult = await this.$store.dispatch("ajaxRequest", { args:args });     
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }

        // actualizo finder facturas
        this.$emit('onEvent', { accion:'reloadFras' });
      },


      // liquidar facturas
      async liquidar() {
        if (!this.validar(2)) return; //validamos cuenta cif y banco      

        //let imp=Math.round((this.total*100),2)/100;

        //si ha cambiado importe cuando solo una factura -> parcial en linea factura
        if (this.total!=this.schema.ctrls.tot.value) {
          this.auxRecord[0].pte=this.schema.ctrls.tot.value;
        }

        let args = { tipo:'fnc', accion: 'facturas', fn_args: { 
          accion:'pagos',
          fnt:'set',
          tipo:this.tipo,
          idP:0,
          ban:this.schema.ctrls.banco.value,
          fpa:this.schema.ctrls.fpago.value,
          obs:this.schema.ctrls.obs.value,
          orden:this.schema.ctrls.orden.value,          
          //imp:this.total,
          imp:this.schema.ctrls.tot.value,
          gas:this.schema.ctrls.gas.value,
          tipo_cta:this.accionRow.tipo_cta,
          cta_id:this.accionRow.cta_id,
          fras:this.auxRecord,
          asi:this.schema.ctrls.asi.value,
          fh:this.schema.ctrls.fh.value
        }};
        
        let apiResult = await this.$store.dispatch("ajaxRequest", { args:args });
        if (apiResult.sql.error) {
          this.$root.$alert.open(apiResult.sql.msg, 'error');
          return;
        }           
        
        this.$root.$alert.open('Factura LIQUIDADA Asiento: <b> ' + apiResult.r[0][0] + "</b><br>" , 'success');
        this.dialog= false;

        this.$emit('onEvent', { accion:'reloadFras' });
      },

      validar(tp) {
        let msg='';
        if (this.accionRow.cif=='') msg+='* La cuenta debe tener CIF<br>';
        if (tp==1 && this.accionRow.ctaban=='') msg+='* El proveedor No tiene Cuenta Bancaria<br>';
        
        if (msg!='') {
          this.$root.$alert.open("<u>No se puede realizar el Cobro/Pago</u><br><br>" + msg, 'error');        
          return false;
        }
        return true;
      },

      //
      errorRecords() {
        this.$root.$alert.open('Debe seleccionar alguna Factura', 'error');
      },

      calcula_gastos(item=1) {
        console.log('calcula_gastos',this.tipo,item,this.schema.ctrls.gas.value);
        item=this.schema.ctrls.fpago.value;
        
        if (this.tipo=='1'||item=='1') this.schema.ctrls.gas.value=0;
        if (item=='2' && this.tipo=='2') this.schema.ctrls.gas.value=Math.round((this.total*100)*0.009,2)/100;

      }

    }
  };
</script>
